<template>
  <div />
</template>

<script>
import loadScript from 'utils/loadScript'
import { createPaypalOrder } from 'api/paypal'

export default {
  name: 'MPaypal',
  props: {
    pence: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      default: 'GBP',
      validator (value) {
        return [
          'AUD', 'CAD', 'CHF', 'CZK',
          'DKK', 'EUR', 'GBP', 'HKD',
          'HUF', 'JPY', 'NOK', 'NZD',
          'PLN', 'SEK', 'SGD', 'USD'
        ].includes(value)
      }
    },
    options: {
      type: Object,
      default () {
        return {
          label: 'checkout',
          size: 'responsive',
          shape: 'rect',
          color: 'gold'
        }
      }
    }
  },
  mounted () {
    loadScript(`${process.env.VUE_APP_PAYPAL_URL}?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=${this.currency}&merchant-id=${process.env.VUE_APP_PAYPAL_MERCHANT_ID}&disable-funding=card`, () => {
      window.paypal.Buttons({
        style: this.options,
        createOrder: this.createOrder,
        onApprove: this.onApprove,
        onCancel: this.cancelled
      }).render(this.$el).catch(e => console.error('Failed to render PayPal button', e))
    })
  },
  methods: {
    async createOrder () {
      const { data } = await createPaypalOrder({ amount: (this.pence / 100).toFixed(2) })
      return data.order_id
    },
    async onApprove (data) {
      this.$emit('approved', data)
    },
    cancelled () {
      this.$emit('cancelled')
    }
  }
}
</script>
